<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('plans.plans') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>

      <div class="card-body">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('plans.plans')">
            <div class="form-group row">


              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.name') }} ({{ $t('arabic') }})<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name.ar" class="form-control" :class="validation && validation['name.ar'] ? 'is-invalid' : ''" :placeholder="$t('plans.name') +' ('+  $t('arabic') +')'"/>
                <span v-if="validation && validation['name.ar']" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation['name.ar'][0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.name') }} ({{ $t('english') }})<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name.en" class="form-control" :class="validation && validation['name.en'] ? 'is-invalid' : ''" :placeholder="$t('plans.name') +' ('+  $t('english') +')'"/>
                <span v-if="validation && validation['name.en']" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation['name.en'][0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.modules_used') }}</label>
                <multiselect v-model="modules"
                             label="module_name"
                             track-by="id"
                             :placeholder="$t('plans.modules_used')"
                             :options="module_settings"
                             :multiple="true"
                             :taggable="false"
                             :class="validation && validation.modules ? 'is-invalid' : ''"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.modules" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.modules[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.date" class="form-control" :class="validation && validation.date ? 'is-invalid' : ''" :placeholder="$t('plans.date')"/>
                <span v-if="validation && validation.date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.date[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.price_per_month') }}</label>
                <input type="number" min="0" v-model="data.price_per_month" class="form-control" :class="validation && validation.price_per_month ? 'is-invalid' : ''" :placeholder="$t('plans.price_per_month')"/>
                <span v-if="validation && validation.price_per_month" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.price_per_month[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.price_per_3_month') }}</label>
                <input type="number" min="0" v-model="data.price_per_3_month" class="form-control" :class="validation && validation.price_per_3_month ? 'is-invalid' : ''" :placeholder="$t('plans.price_per_3_month')"/>
                <span v-if="validation && validation.price_per_3_month" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.price_per_3_month[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.price_per_6_month') }}</label>
                <input type="number" min="0" v-model="data.price_per_6_month" class="form-control" :class="validation && validation.price_per_6_month ? 'is-invalid' : ''" :placeholder="$t('plans.price_per_6_month')"/>
                <span v-if="validation && validation.price_per_6_month" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.price_per_6_month[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.price_per_year') }}</label>
                <input type="number" min="0" v-model="data.price_per_year" class="form-control" :class="validation && validation.price_per_year ? 'is-invalid' : ''" :placeholder="$t('plans.price_per_year')"/>
                <span v-if="validation && validation.price_per_year" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.price_per_year[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.currency') }}<span class="text-danger">*</span></label>
                <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.currency_id[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.plan_type') }}<span class="text-danger">*</span></label>
                <select name="" id="plan_type" v-model="data.plan_type" class="custom-select" :class="validation && validation.plan_type ? 'is-invalid' : ''">
                  <option v-for="row in plan_types" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.plan_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.plan_type[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_company_branches') }}</label>
                  <b-form-checkbox v-model="number_company_branches_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_company_branches_unlimited" v-model="data.number_company_branches" class="form-control" :class="validation && validation.number_company_branches ? 'is-invalid' : ''" :placeholder="$t('plans.number_company_branches')"/>
                <span v-if="validation && validation.number_company_branches" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_company_branches[0] }}
                                    </span>


              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_company_inventory') }}</label>
                  <b-form-checkbox v-model="number_company_inventory_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_company_inventory_unlimited" v-model="data.number_company_inventory" class="form-control" :class="validation && validation.number_company_inventory ? 'is-invalid' : ''" :placeholder="$t('plans.number_company_inventory')"/>
                <span v-if="validation && validation.number_company_inventory" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_company_inventory[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_pos') }}</label>
                  <b-form-checkbox v-model="number_pos_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_pos_unlimited" v-model="data.number_pos" class="form-control" :class="validation && validation.number_pos ? 'is-invalid' : ''" :placeholder="$t('plans.number_pos')"/>
                <span v-if="validation && validation.number_pos" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_pos[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_employee') }}</label>
                  <b-form-checkbox v-model="number_employee_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_employee_unlimited" v-model="data.number_employee" class="form-control" :class="validation && validation.number_employee ? 'is-invalid' : ''" :placeholder="$t('plans.number_employee')"/>
                <span v-if="validation && validation.number_employee" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_employee[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_customers') }}</label>
                  <b-form-checkbox v-model="number_customers_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_customers_unlimited" v-model="data.number_customers" class="form-control" :class="validation && validation.number_customers ? 'is-invalid' : ''" :placeholder="$t('plans.number_customers')"/>
                <span v-if="validation && validation.number_customers" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_customers[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_items') }}</label>
                  <b-form-checkbox v-model="number_items_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_items_unlimited" v-model="data.number_items" class="form-control" :class="validation && validation.number_items ? 'is-invalid' : ''" :placeholder="$t('plans.number_items')"/>
                <span v-if="validation && validation.number_items" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_items[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_users') }}</label>
                  <b-form-checkbox v-model="number_user_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_user_unlimited" v-model="data.number_user" class="form-control" :class="validation && validation.number_user ? 'is-invalid' : ''" :placeholder="$t('plans.number_users')"/>
                <span v-if="validation && validation.number_user" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_user[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('plans.number_sales_invoices') }}</label>
                  <b-form-checkbox v-model="number_sales_invoice_unlimited" name="check-button" switch>{{ $t('plans.unlimited') }}</b-form-checkbox>
                </div>
                <input type="number" min="0" :disabled="number_sales_invoice_unlimited" v-model="data.number_sales_invoice" class="form-control" :class="validation && validation.number_sales_invoice ? 'is-invalid' : ''" :placeholder="$t('plans.number_sales_invoices')"/>
                <span v-if="validation && validation.number_sales_invoice" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.number_sales_invoice[0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.additional_pos_price') }}</label>
                <input type="number" min="0" v-model="data.additional_pos_price" class="form-control" :class="validation && validation.additional_pos_price ? 'is-invalid' : ''" :placeholder="$t('plans.additional_pos_price')"/>
                <span v-if="validation && validation.additional_pos_price" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.additional_pos_price[0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.additional_branch_price') }}</label>
                <input type="number" min="0" v-model="data.additional_branch_price" class="form-control" :class="validation && validation.additional_branch_price ? 'is-invalid' : ''" :placeholder="$t('plans.additional_branch_price')"/>
                <span v-if="validation && validation.additional_branch_price" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.additional_branch_price[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.extension_days') }}</label>
                <input type="number" min="0" v-model="data.extension_days" class="form-control" :class="validation && validation.extension_days ? 'is-invalid' : ''" :placeholder="$t('plans.extension_days')"/>
                <span v-if="validation && validation.extension_days" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.extension_days[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.additional_user_price') }}</label>
                <input type="number" min="0" v-model="data.additional_user_price" class="form-control" :class="validation && validation.additional_user_price ? 'is-invalid' : ''" :placeholder="$t('plans.additional_user_price')"/>
                <span v-if="validation && validation.additional_user_price" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.additional_user_price[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.maximum_amount_sales') }}</label>
                <input type="number" min="0" v-model="data.maximum_amount_sales" class="form-control" :class="validation && validation.maximum_amount_sales ? 'is-invalid' : ''" :placeholder="$t('plans.maximum_amount_sales')"/>
                <span v-if="validation && validation.maximum_amount_sales" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.maximum_amount_sales[0] }}
                                    </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.priority') }}<span class="text-danger">*</span></label>
                <select name="" id="priority" v-model="data.priority" class="custom-select" :class="validation && validation.priority ? 'is-invalid' : ''">
                  <option v-for="row in priorities" :value="row" :key="row">{{ row }}</option>
                </select>
                <span v-if="validation && validation.priority" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.priority[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('plans.notes') }}</label>
                <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('plans.notes')"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.notes[0] }}
                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_custom_program" name="check-button" switch>{{ $t('plans.is_custom_program') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_free" name="check-button" switch>{{ $t('plans.free_plan') }}</b-form-checkbox>
              </div>
<!--              <div v-if="data.is_free" class="col-lg-6 mb-5">-->
<!--                <label>{{ $t('plans.available_days') }}</label>-->
<!--                <input type="number" min="0" v-model="data.available_days" class="form-control" :class="validation && validation.available_days ? 'is-invalid' : ''" :placeholder="$t('plans.available_days')"/>-->
<!--                <span v-if="validation && validation.available_days" class="fv-plugins-message-container invalid-feedback">-->
<!--                                        {{ validation.available_days[0] }}-->
<!--                                    </span>-->
<!--              </div>-->




              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_free_trial" name="check-button" switch>{{ $t('plans.is_free_trial') }}</b-form-checkbox>
              </div>
              <div v-if="data.is_free_trial" class="col-lg-6 mb-5">
                <label>{{ $t('plans.free_trial_days') }}</label>
                <input type="number" min="0" v-model="data.free_trial_days" class="form-control" :class="validation && validation.free_trial_days ? 'is-invalid' : ''" :placeholder="$t('plans.free_trial_days')"/>
                <span v-if="validation && validation.free_trial_days" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.free_trial_days[0] }}
                                    </span>
              </div>

            </div>
          </b-tab>
          <b-tab :title="$t('plans.basic_features')">
            <div class="row">
              <div class="col-md-12">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                  <h6 class="mt-2">{{ $t('plans.basic_features') }}</h6>
                  <div class="d-flex">
                    <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
                  </div>
                </div>
                <div>
                  <table class="table">
                    <thead>
                    <tr>
                      <th>{{$t('plans.title')}}</th>
                      <th>{{$t('plans.summary')}}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in basic_features" :key="'feature_'+index">
                      <td><input type="text" class="form-control" v-model="row.name"></td>
                      <td><textarea class="form-control" v-model="row.description"></textarea></td>
                      <td>
                        <v-icon style="color: #dc3545;" class="m-40-4" small @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


          </b-tab>

          <b-tab :title="$t('plans.offers_applied')">
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th>{{$t('plans.offer_name')}}</th>
                  <th>{{$t('plans.offer_date')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{offer.name}}</td>
                  <td>{{offer.start_date}} - {{offer.end_date}}</td>
                </tr>
                <tr v-if="offer.offers_plans" class="child-1">
                  <td colspan="5">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>{{ $t('offers.plan') }}</th>
                        <th>{{ $t('offers.interval') }}</th>
                        <th>{{ $t('offers.percentage') }}</th>
                        <th>{{ $t('offers.free_days') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row, index) in applied_offers" :key="'offers_plans_'+index">
                        <td>{{row.plan_name}}</td>
                        <td>{{row.interval}}</td>
                        <td>{{row.percentage}}</td>
                        <td>{{row.free_days}}</td>
                      </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </b-tab>
          
          <b-tab :title="$t('plans.offers_log')">
            <div>
              <table class="table">
                <thead>
                <tr>
                  <th>{{$t('offers.name')}}</th>
                  <th>{{$t('offers.users_number')}}</th>
                  <th>{{$t('offers.start_date')}}</th>
                  <th>{{$t('offers.end_date')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in offers_log" :key="'offer_'+index">
                  <td>{{row.name}}</td>
                  <td>{{row.users_number}}</td>
                  <td>{{row.start_date}}</td>
                  <td>{{row.end_date}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-tab>

          
          <b-tab :title="$t('plans.plan_services')">
            <div class="row">
              
                <div class="col-lg-6 mb-5">
                    <label>{{ $t('plans.branch_price') }}</label>
                    <input type="number" min="0" v-model="data.branch_price" class="form-control" :placeholder="$t('plans.branch_price')"/>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{ $t('plans.user_price') }}</label>
                    <input type="number" min="0" v-model="data.user_price" class="form-control" :placeholder="$t('plans.user_price')"/>
                </div>

                <div class="col-lg-6 mb-5">
                    <label>{{ $t('plans.pos_price') }}</label>
                    <input type="number" min="0" v-model="data.pos_price" class="form-control" :placeholder="$t('plans.pos_price')"/>
                </div>

            </div>
          </b-tab>

          <b-tab :title="$t('plans.print_setting_details')">
            <!-- <div class="card card-custom">
              <div class="card-body row">
                <div class="d-flex col-lg-12 mb-5">
                  <b-form-group label="" v-slot="{ ariaDescribedby }"  class="col-12">
                    <b-form-checkbox-group id="checkbox-group-2" v-model="data.print_settings" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                      <b-form-checkbox v-for="(row, index) in print_setting_list" :value="row.type" :key="index" class="col-md-5 mt-5 mb-5">{{ row.type_name }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div> -->
            
            <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12">
              <b-form-checkbox v-model="selectAllChecked" @change="checkAllPrintSettings" class="mr-2">{{ $t('select_all') }}</b-form-checkbox>
              <!-- Checkbox Group -->
              <b-form-checkbox-group id="checkbox-group-2" v-model="data.print_settings" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                <b-form-checkbox v-for="(row, index) in print_setting_list" :value="row.type" :key="index" class="col-md-5 mt-5 mb-5">
                  {{ row.type_name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-tab>

          <b-tab :title="$t('plans.invoices_bond_configuration')">
            
            <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12">
              <b-form-checkbox v-model="selectAllCheckedBond" @change="checkAllInvoicesBondConfiguration" class="mr-2">{{ $t('select_all') }}</b-form-checkbox>
              <!-- Checkbox Group -->
              <b-form-checkbox-group id="checkbox-group-2" v-model="data.invoices_bond_configuration" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                <b-form-checkbox v-for="(row, index) in invoices_bond_configuration_list" :value="row.slug" :key="index" class="col-md-5 mt-5 mb-5">
                  {{ row.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-tab>

          <!-- <b-tab :title="$t('plans.code_settings')">
            
            <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12">
              <b-form-checkbox v-model="selectAllCheckedCode" @change="checkAllCodeSettings" class="mr-2">{{ $t('select_all') }}</b-form-checkbox>
              <b-form-checkbox-group id="checkbox-group-2" v-model="data.code_settings" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row">
                <b-form-checkbox v-for="(row, index) in code_settings_list" :value="row.type_type" :key="index" class="col-md-5 mt-5 mb-5">
                  {{ row.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-tab> -->
        </b-tabs>

      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-6">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {

  data() {
    return {
      mainRoute: '/subscription/plans',
      subRoute: '/subscription/plan',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : '',

      data: {
        name: {ar: '', en: ''},
        modules: [],
        date: null,
        price_per_month: null,
        price_per_year: null,
        currency_id: null,
        plan_type: null,
        number_company_branches: null,
        number_company_inventory: null,
        number_pos: null,
        number_employee: null,
        number_customers: null,
        number_items: null,
        number_user: null,
        number_sales_invoice: null,
        is_active: false,
        available_days: null,
        is_free: false,
        price_per_3_month: null,
        price_per_6_month: null,
        notes: null,
        priority: 1,
        additional_pos_price: null,
        additional_branch_price: null,
        extension_days: null,
        additional_user_price: null,
        maximum_amount_sales: null,
        is_free_trial: false,
        is_custom_program: false,
        free_trial_days: null,
        print_settings: [],
        invoices_bond_configuration: [],
        code_settings: [],
        branch_price: null,
        user_price: null,
        pos_price: null,
      },
      isEditing: false,
      validation: null,
      currencies: [],
      services_type: [],
      module_settings: [],
      modules: [],
      plan_types: [],
      priorities: [1,2,3,4,5,6,7,8,9,10],
      basic_features: [],
      basic_features_form: {id:null, name:null, description: null},

      number_company_branches_unlimited: false,
      number_company_inventory_unlimited: false,
      number_pos_unlimited: false,
      number_employee_unlimited: false,
      number_customers_unlimited: false,
      number_items_unlimited: false,
      number_user_unlimited: false,
      number_sales_invoice_unlimited: false,
      unlimited_number: 9999999999,
      offers_log: [],
      offer: [],
      applied_offers: [],
      print_setting_list : [],
      invoices_bond_configuration_list : [],
      code_settings_list : [],
      selectAllChecked: false, // Initialize the master checkbox value
      selectAllCheckedBond : false,
      selectAllCheckedCode : false,
    };
  },
  watch: {
    modules: function (val) {
      this.data.modules = [];
      if (val) {
        this.data.modules = val.map(row => row.id);
      }
    },
    "data.is_free": function (val) {
      if (!val) {
        this.data.available_days = null;
      }
      // else {
      //   // this.data.price_per_month = '0';
      //   // this.data.price_per_year = '0';
      //   // this.data.price_per_3_month = '0';
      //   // this.data.price_per_6_month = '0';
      //
      //   this.data.is_free_trial = false;
      // }
    },
    "data.is_free_trial": function (val) {
      if (!val) {
        this.data.free_trial_days = null;
      }
      // else {
      //   this.data.is_free = false;
      // }
    },
    number_company_branches_unlimited: function (val) {
      if (val) {
        this.data.number_company_branches = this.unlimited_number;
      }
    },
    number_company_inventory_unlimited: function (val) {
      if (val) {
        this.data.number_company_inventory = this.unlimited_number;
      }
    },
    number_pos_unlimited: function (val) {
      if (val) {
        this.data.number_pos = this.unlimited_number;
      }
    },
    number_employee_unlimited: function (val) {
      if (val) {
        this.data.number_employee = this.unlimited_number;
      }
    },
    number_customers_unlimited: function (val) {
      if (val) {
        this.data.number_customers = this.unlimited_number;
      }
    },
    number_items_unlimited: function (val) {
      if (val) {
        this.data.number_items = this.unlimited_number;
      }
    },
    number_user_unlimited: function (val) {
      if (val) {
        this.data.number_user = this.unlimited_number;
      }
    },
    number_sales_invoice_unlimited: function (val) {
      if (val) {
        this.data.number_sales_invoice = this.unlimited_number;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        basic_features: this.basic_features,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'plans.index'});
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        basic_features: this.basic_features,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'plans.index'});
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {

      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.modules = response.data.data.modules;
        this.data.date = response.data.data.date;
        this.data.price_per_month = response.data.data.price_per_month;
        this.data.price_per_year = response.data.data.price_per_year;
        this.data.currency_id = response.data.data.currency_id;
        this.data.plan_type = response.data.data.plan_type;
        this.data.number_company_branches = response.data.data.number_company_branches;
        this.data.number_company_inventory = response.data.data.number_company_inventory;
        this.data.number_pos = response.data.data.number_pos;
        this.data.number_employee = response.data.data.number_employee;
        this.data.number_customers = response.data.data.number_customers;
        this.data.number_items = response.data.data.number_items;
        this.data.number_user = response.data.data.number_user;
        this.data.number_sales_invoice = response.data.data.number_sales_invoice;
        this.data.is_active = response.data.data.is_active;
        this.data.available_days = response.data.data.available_days;
        this.data.is_free = response.data.data.is_free;
        this.data.price_per_3_month = response.data.data.price_per_3_month;
        this.data.price_per_6_month = response.data.data.price_per_6_month;
        this.data.notes = response.data.data.notes;
        this.data.priority = response.data.data.priority;
        this.data.additional_pos_price = response.data.data.additional_pos_price;
        this.data.additional_branch_price = response.data.data.additional_branch_price;
        this.data.extension_days = response.data.data.extension_days;
        this.data.additional_user_price = response.data.data.additional_user_price;
        this.data.maximum_amount_sales = response.data.data.maximum_amount_sales;
        this.data.is_free_trial = response.data.data.is_free_trial;
        this.data.free_trial_days = response.data.data.free_trial_days;
        this.data.is_custom_program = response.data.data.is_custom_program;

        this.data.branch_price = response.data.data.branch_price;
        this.data.user_price = response.data.data.user_price;
        this.data.pos_price = response.data.data.pos_price;

        this.number_company_branches_unlimited = response.data.data.number_company_branches == this.unlimited_number;
        this.number_company_inventory_unlimited = response.data.data.number_company_inventory == this.unlimited_number;
        this.number_pos_unlimited = response.data.data.number_pos == this.unlimited_number;
        this.number_employee_unlimited = response.data.data.number_employee == this.unlimited_number;
        this.number_customers_unlimited = response.data.data.number_customers == this.unlimited_number;
        this.number_items_unlimited = response.data.data.number_items == this.unlimited_number;
        this.number_user_unlimited = response.data.data.number_user == this.unlimited_number;
        this.number_sales_invoice_unlimited = response.data.data.number_sales_invoice == this.unlimited_number;

        this.modules = this.module_settings.filter((row) => response.data.data.modules.includes(row.id));

        this.data.print_settings = response.data.data.print_settings ? response.data.data.print_settings : [];

        this.data.invoices_bond_configuration = response.data.data.invoices_bond_configuration ? response.data.data.invoices_bond_configuration : [];
        this.data.code_settings = response.data.data.code_settings ? response.data.data.code_settings : [];


        this.offer = response.data.data.offer ? response.data.data.offer : [];
        this.applied_offers = response.data.data.applied_offers ? response.data.data.applied_offers : [];
        this.basic_features = response.data.data.basic_features ? response.data.data.basic_features : [];

        if (!response.data.data.basic_features){
          this.addItemRowToList();
        }else {
          if (response.data.data.basic_features.length <= 0){
            this.addItemRowToList();
          }
        }

      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    // getServicesType() {
    //   ApiService.get(`${this.mainRouteDependency}/service_types`).then((response) => {
    //     this.services_type = response.data.data;
    //   });
    // },

    async getModuleSettings() {
      await ApiService.get(this.mainRouteDependency + "/module-settings").then((response) => {
        this.module_settings = response.data.data;
      });
    },

    checkAllPrintSettings() {
      if (this.selectAllChecked) {
        this.data.print_settings = this.print_setting_list.map((row) => row.type);
      } else {
        this.data.print_settings = [];
      }
    },

     async getPrintSettingDetails() {
      await ApiService.get(this.subRoute + "/print_setting_details").then((response) => {
        response.data.data.forEach((row)=>{
          if (row.type){
              this.print_setting_list.push(row);
          }
        });
      });
    },
    

    checkAllInvoicesBondConfiguration() {
      if (this.selectAllCheckedBond) {
        this.data.invoices_bond_configuration = this.invoices_bond_configuration_list.map((row) => row.slug);
      } else {
        this.data.invoices_bond_configuration = [];
      }
    },

    checkAllCodeSettings() {
      if (this.selectAllCheckedCode) {
        this.data.code_settings = this.code_settings_list.map((row) => row.type_type);
      } else {
        this.data.code_settings = [];
      }
    },

    async getInvoicesBondConfiguration() {
      await ApiService.get(this.subRoute + "/invoices_bond_configuration").then((response) => {
        response.data.data.forEach((row)=>{
            if (row.slug){
              this.invoices_bond_configuration_list.push(row);
          }
        });
      });
    },

    async getCodeSettings() {
      await ApiService.get(this.subRoute + "/code_settings").then((response) => {
        response.data.data.forEach((row)=>{
            if (row.type_type){
              this.code_settings_list.push(row);
          }
        });
      });
    },

    getPlanTypes() {
      ApiService.get(this.mainRouteDependency + "/plan_types").then((response) => {
        this.plan_types = response.data.data;
      });
    },
    getOffersForPlan(plan_id) {
      if (plan_id)
      ApiService.get(this.mainRouteDependency + "/offer_for_plan/"+plan_id).then((response) => {
        this.offers_log = response.data.data;
      });
    },
    addItemRowToList() {
      this.basic_features.unshift(this.basic_features_form);
      this.basic_features_form = {id:null, name:null, description: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.basic_features.length >= 1) {
        this.basic_features.splice(index, 1);
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.plans"), route: {name: 'plans.index'}}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    
    this.getCurrencies();
    // this.getServicesType();
    this.getPlanTypes();

    let promise = this.getModuleSettings();
    let promise2 = this.getPrintSettingDetails();
    let promise3 = this.getInvoicesBondConfiguration();
    // let promise4 = this.getCodeSettings();


    if(!this.idEdit){
      this.addItemRowToList();
    }
    // promise4
    Promise.all([promise,promise2,promise3]).then(() => {
      if (this.idEdit) {
        this.getOffersForPlan(this.idEdit);
        this.getData();
      }
    })


  },
};
</script>


